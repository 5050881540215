import { ClockIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import Header from './Components/Header'
import Sidebar from './Components/Sidebar'

import classes from './PreviewCurriculum.module.css'
import { useEffect, useState } from 'react'
import useCourse from 'Services/Courses/Hooks/useCourse'
import { useParams } from 'react-router-dom'
import { ILesson, IModule } from './typings'
import { TLessonContent } from 'Services/Courses'
import Button from 'Components/Button/Button'
import VideoPlayer from 'Components/VideoPlayer/VideoPlayer'
import { Editor } from '@monaco-editor/react'
import { DocumentViewer } from 'react-documents'

const getContent = (lesson: TLessonContent, type: string): string => {
  if (type === 'reading') {
    return lesson?.details?.reading_contents || ''
  }

  if (type === 'video') {
    return lesson?.details?.video_url || ''
  }

  if (type === 'customize') {
    return JSON.stringify(lesson?.details?.blocks || '')
  }

  if (type === 'presentation') {
    return lesson?.details?.file_urls || ''
  }

  if (['quiz', 'assignment', 'final_exam'].includes(type)) {
    return JSON.stringify({
      instructions: lesson?.details?.instructions || '',
      link: lesson?.details?.link || '',
    })
  }

  return ''
}

const AssessmentToolsView = (props: {
  title: string
  content?: string
  type: string
}) => {
  const { title, content, type } = props
  const parsedContent = content ? JSON.parse(content) : {}

  return (
    <div className="bg-white w-[494px] mx-auto rounded-[10px] px-10 py-[50px]">
      <h2 className="text-[25px] font-medium mb-5">{title}</h2>

      <div className="flex">
        <p className="break-all">{parsedContent?.instructions}</p>
      </div>

      <div className="flex justify-center mt-10">
        <Button>
          <a href={parsedContent?.link} className="flex items-center gap-x-3">
            Take {type} <ArrowRightIcon />
          </a>
        </Button>
      </div>
    </div>
  )
}

const ReadingView = (props: { title: string; content: string }) => {
  const { title, content } = props

  return (
    <div className={classes.rightSide}>
      <div className={classes.top}>
        <h3>{title}</h3>

        <p>
          <ClockIcon className="text-[#2BA792]" />
          <span>5mins</span>
        </p>
      </div>

      <div className={classes.bottom}>{content}</div>
    </div>
  )
}

const VideoView = (props: { title: string; content: string }) => {
  const { title, content } = props

  return (
    <div className={classes.rightSide}>
      <div className={classes.top}>
        <h3>{title}</h3>
      </div>

      <div className={classes.bottom}>
        <VideoPlayer height="500px" url={content} />
      </div>
    </div>
  )
}

const PresentationView = (props: { title: string; content: string }) => {
  const { title, content } = props

  return (
    <div className={classes.rightSide}>
      <div className={classes.top}>
        <h3>{title}</h3>
      </div>

      <div className={classes.bottom}>
        <DocumentViewer
          url={content}
          style={{ height: '70vh', width: '100%' }}
        />
      </div>
    </div>
  )
}

const CustomizeView = (props: { title: string; content: string }) => {
  const { title, content } = props
  const parsedContent: { type: string; value: string }[] = content
    ? JSON.parse(content)
    : []

  return (
    <div className={classes.rightSide}>
      <div className={classes.top}>
        <h3>{title}</h3>
      </div>

      <div className={`${classes.bottom} space-y-6`}>
        {parsedContent.map((item) => {
          if (item.type === 'text') return <section>{item.value}</section>
          if (item.type === 'code') {
            return (
              <Editor
                height="400px"
                defaultLanguage="javascript"
                defaultValue={item.value}
                className="border"
                options={{ readOnly: true }}
              />
            )
          }

          if (item.type === 'video') {
            return <VideoPlayer height="400px" url={item.value} />
          }

          return null
        })}
      </div>
    </div>
  )
}

const LessonView = (props: { lesson?: ILesson }) => {
  const { lesson } = props

  if (['quiz', 'assignment', 'final_exam'].includes(lesson?.type || '')) {
    return (
      <AssessmentToolsView
        title={lesson?.title || ''}
        type={lesson?.type || ''}
        content={lesson?.content}
      />
    )
  }

  if (lesson?.type === 'video') {
    return <VideoView title={lesson.title} content={lesson.content} />
  }

  if (lesson?.type === 'customize') {
    return <CustomizeView title={lesson.title} content={lesson.content} />
  }

  if (lesson?.type === 'presentation') {
    return <PresentationView title={lesson.title} content={lesson.content} />
  }

  if (lesson?.type === 'reading') {
    return (
      <ReadingView
        title={lesson?.title || ''}
        content={lesson?.content || ''}
      />
    )
  }

  return null
}

const PreviewCurriculum = () => {
  const [currentModule, setCurrentModule] = useState<IModule>()
  const [currentLesson, setCurrentLesson] = useState<ILesson>()

  const { courseId } = useParams()
  const { useGetCurriculumByCourseId } = useCourse()

  const { data: response, isLoading } = useGetCurriculumByCourseId(
    courseId || null
  )

  const curriculum = response?.data

  const courseModules = curriculum?.course_modules.map((courseModule) => {
    return {
      id: courseModule.id,
      moduleTitle: courseModule.title,
      lessons: courseModule.course_weeks.flatMap((week) =>
        week.content?.map((lesson) => ({
          id: lesson.id,
          title: lesson.title,
          type: lesson.type,
          content: getContent(lesson, lesson.type),
        }))
      ),
    }
  })

  useEffect(() => {
    if (courseModules) {
      if (!currentModule) {
        setCurrentModule(courseModules[0])
      }

      if (!currentLesson) {
        setCurrentLesson(courseModules[0]?.lessons[0])
      }
    }
  }, [curriculum])

  return (
    <div className={classes.container}>
      <Header />

      <div className={classes.body}>
        <Sidebar
          curriculumTitle={curriculum?.title || ''}
          courseModules={courseModules || []}
          currentLesson={currentLesson}
          setCurrentLesson={(value: any) => setCurrentLesson(value)}
          setCurrentModule={(value: any) => setCurrentModule(value)}
          currentModule={currentModule}
        />

        <LessonView lesson={currentLesson} />
      </div>
    </div>
  )
}

export default PreviewCurriculum
